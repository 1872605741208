import React from "react";
import '../../Location-and-surroundings/locationAndsurroundings.scss';

function PuriJagannathTemple(){
    return(
       <>
       <div className="main-banner" style={{background: 'url(assets/images/jagannathtemple.jpg)', backgroundSize: 'cover'}}>
    <div className="banner-caption">
        <span>Hyderabad</span>
        <h1>Puri Jagannath Temple </h1>
    </div>
</div>
<div className="container">
    <div className="about-destination">
        <div className="heading">
            <h3>Puri Jagannath Temple </h3>
        </div>
        <div className="row mb-3">
            <div className="col-md-12">
                <p className="text">Situated at the heart of the city, in Banjara Hills, Jagannath Temple is Hyderabad’s copy of the real Puri Jagannath Temple, which is situated in Orissa. Moreover, the temple is constructed in a similar style to the Puri temple, but the size of both temples varies. The temple is red sandstone and follows the historic Shikara style pattern. Behind the main God, the Jagannath temple has smaller temples devoted to Lakshmi, Shivam Navagraha, Ganesha, Hanuman, and Shiva. </p>
            </div>
        </div>

        <div className="heading">
            <h3>Top Attractions of the Temple</h3>
        </div>
        <p>There are multiple attractions of the Jagannath temple. But the festivals celebrated in the temple are the major ones. Some of the list of the festivals is highlighted here:</p>
        <div className="row mt-5 mb-3">
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/snana-jatre.jpeg" alt="snana-jatre" />
                    <div className="title">
                        <h5>Snana Yatra & Gajanana Vesha</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/janmashtami.jpg" alt="janmashtami" />
                    <div className="title">
                        <h5>
                            Janmashtami
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/rath-yatra.jpg" alt="rath-yatra" />
                    <div className="title">
                        <h5>Rath Yatra (Car/Chariot Festival)</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/ganesh-chaturthi.jpg" alt="ganesh-chaturthi" />
                    <div className="title">
                        <h5>Ganesh Chaturthi</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/diwali.jpg" alt="diwali" />
                    
                    <div className="title">
                        <h5>
                            Deepavali
                        </h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/ekadashi.jpg" alt="ekadashi" />
                    <div className="title">
                        <h5>Vaikuntha Ekadashi</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/sabitri.jpg" alt="sabitri" />
                    <div className="title">
                        <h5>Sabitri Amabasya</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card">
                    <img src="assets/images/durga-pooja.jpg" alt="durga-pooja" />
                    <div className="title">
                        <h5>Durga Puja (Vijaya Dashami)</h5>
                    </div>
                </div>
            </div>
            <p className="text-center" style={{fontSize:'17px'}}>
                The temple also organises the light and sound programs, known as Chakadola, on the appearance of Lord Jagannath.
            </p>
            <p className="text-center mb-2" style={{fontSize:'17px'}}>
                <b>The price for the light & sound show ticket: Rs. 50/-</b>
            </p>
            <p className="text-center" style={{fontSize:'17px'}}>
                <b> Timings for Light & Sound Show: Projected on Saturday, Sunday, and Holidays at 8:00 pm.</b>
            </p>
        </div>
       
    </div>
</div>
<div className="how-to-reach">
    <div className="container">
        <div className="box">
            <div className="heading">
                <h3>Mediums to Travel </h3>
            </div>
            <p>There are multiple ways to reach this temple as it is only 7 Km far from The Superb Hotels. Moreover, you can take a bus, auto-rickshaw, taxi, motorcycle, tourist bus, etc.</p>
        </div>
    </div>
</div>
<div className="additional-details">
    <div className="container">
        <div className="heading">
            <h3>Additional Details</h3>
        </div>
        <div className="row">
            <div className="col-md-3">
                <div className="box">
                    <div className="icon">
                        <i className='bx bx-time-five'></i>
                    </div>
                    <h5>Timings</h5>
                    <span>
                        6:00 AM- 9:00 AM
                    </span>
                </div>
            </div>
            <div className="col-md-3">
                <div className="box">
                    <div className="icon">
                        <i className='bx bx-rupee'></i>
                    </div>
                    <h5>Entry Fee</h5>
                    <span>Free</span>
                </div>
            </div>
            <div className="col-md-3">
                <div className="box">
                    <div className="icon">
                        <i className='bx bx-rupee'></i>
                    </div>
                    <h5>Dress Code</h5>
                    <span>Formal Dress for Men</span>
                </div>
            </div>
            <div className="col-md-3">
                <div className="box">
                    <div className="icon">
                        <i className='bx bx-camera'></i>
                    </div>
                    <h5>Dress Code</h5>
                    <span>Saree or Salwar Kameez for Women</span>
                </div>
            </div>
        </div>
    </div>
</div>
       </>
    )
}

export default PuriJagannathTemple;