import React, { useEffect, useState } from 'react'
import { Col, Row, Modal, Image } from 'antd';
import { gallery13, gallery14, gallery15, gallery16, gallery28, photo_gallery } from '../../utils/Imagepath';
import "../gallery/gallery.scss"
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { galleryImage } from '../../services/EndPoint';

const imgGallery = [
    {
        id: 1,
        imgUrl: gallery13
    },
    {
        id: 2,
        imgUrl: gallery14
    },
    {
        id: 3,
        imgUrl: gallery15
    },
    {
        id: 4,
        imgUrl: gallery16
    },

];


const HotelGallery = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [getImgGallery, setGetImgGallery] = useState([])

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const [showGallery, setShowGallery] = useState(false);

    const toggleGallery = () => {
        setShowGallery(!showGallery);
    };

    const handleCloseModal = () => {
        setShowGallery(false);
    };

    useEffect(() => {
        galleryImage()
            .then((galleryData) => {
                setGetImgGallery(galleryData?.data?.data);
            })
            .catch((error) => {
                console.error('Error fetching gallery images:', error);
            });
    }, []);

    const galleryOptions = {
        showFullscreenButton: true,
        showPlayButton: true,
        slideInterval: 2000,
    };

    return (
        <>
            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb  me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><Link to="/" style={{ color: '#000' }}>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                    </ol>
                </div>
            </nav>
            <section className="container hotel_confirm mb-4">

                <div className="d-flex justify-content-between align-items-center mb-4">
                    <div className="heading mb-0">
                        <h3>Gallery</h3>
                    </div>

                    <div className='more_photos mt-0'>
                        <Link to="" className='more_photos_link' onClick={toggleGallery}>
                            <img src={photo_gallery} className="mb-0 mx-2" style={{ width: "24px", height: "24px" }} />
                            <span className="more_photos_text">See more photos ({getImgGallery.length})</span>
                        </Link>
                    </div>
                </div>
                <Row gutter={16}>
                    <Col md={12} xs={24}>
                        <Image src={selectedImage?.imgUrl ? selectedImage?.imgUrl : gallery28} className="img-fluid rounded mb-3" />
                    </Col>
                    <Col md={12} xs={24}>
                        <Row gutter={16}>
                            {imgGallery.map(image => (
                                <Col key={image.id} md={12} xs={24} className=''>
                                    <div className="hover14">
                                        <figure>
                                            <img
                                                src={image?.imgUrl}
                                                className="img-fluid rounded cursor-pointer"
                                                onClick={() => handleImageClick(image)}
                                            />
                                        </figure>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                </Row>

                <div>
                    <Modal
                        open={showGallery}
                        footer={null}
                        onCancel={handleCloseModal}
                        centered
                        width={1000}
                        destroyOnClose={true}
                    >
                        {showGallery && (
                            <div className="gallery-container">
                                <ImageGallery
                                    items={getImgGallery.map(image => ({ original: image?.image_url, thumbnail: image?.image_url, fullscreen: image?.image_url }))}
                                    showThumbnails={true}
                                    showBullets={true}
                                    {...galleryOptions}
                                />
                            </div>
                        )}
                    </Modal>



                </div>
            </section>
        </>
    )
}

export default HotelGallery