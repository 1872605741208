import { Row, Card, Col, Modal, Checkbox, Button } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getbookingCancel } from '../../services/EndPoint';
import moment from 'moment';

const HotelCancellationModal = ({ item, handleOk, handleCancel, setRender, isModalVisible }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [cancelReq, setCancelReq] = useState(null);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };


    const fetchBookingCancel = () => {
        const payload = {
            booking_id: item?.booking_id
        };

        getbookingCancel(payload)
            .then((response) => {
                console.log("response", response)
                setCancelReq(true)
                setTimeout(() => {
                    setCancelReq(false);
                    handleCancel()
                    setRender(response)
                }, 2000);
            })
            .catch((error) => {
                console.error("Error fetching bookings:", error);
            });
    };

    return (
        <div>
            <Modal
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
                width={500}
                centered
            >
                <div className='text-center'>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} lg={24}>
                            <Card className='booking-card w-100 p-4' >

                                {!cancelReq ?
                                    <>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <h3 className='booking-title mb-4'>Cancel Booking</h3>
                                            <hr />
                                        </div>

                                        <p className='mb-0'>
                                            Your booking at <strong>The Superb Hotel</strong>{" "} from {" "}
                                            <strong>{moment(item?.booking_from).format('DD MMM, YYYY')}</strong> to {""}
                                            <strong>{moment(item?.booking_to).format('DD MMM, YYYY')}</strong> will be canceled.
                                        </p>
                                        <br />
                                        <p> Are you sure you want to proceed? </p>
                                        <p>According to the cancellation policy {" "}
                                            <Link
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open('/payment-policy', '_blank');
                                                }}
                                            >
                                                Payment & Cancellation Policy
                                            </Link></p>

                                        <p> <Checkbox onChange={handleCheckboxChange} />I Acknowledge That I Have Read And Understand The Payment & Cancellation Policy .</p>

                                        <div className="text-center">
                                            <Button className='mx-2 cancellation-button' type="primary" onClick={() => handleCancel()}>Go Back</Button>
                                            <Button className="cancellation-button" type="primary" disabled={!isChecked} onClick={fetchBookingCancel}>
                                                Confirm Cancellation
                                            </Button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <h6 className='px-4 mt-4'>Your cancellation request has been sent to your email. Please check your inbox.</h6>
                                        <Button className='mx-2 cancellation-button' type="primary" onClick={() => handleCancel()}>Go Back</Button>
                                    </>
                                }
                            </Card>

                        </Col>


                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default HotelCancellationModal