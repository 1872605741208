import React, { useEffect, useState } from "react";
import '../Header/Header.scss';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined, DownOutlined, UserOutlined, LogoutOutlined, HistoryOutlined } from '@ant-design/icons';
import { Button, Dropdown, Avatar, message } from "antd";
import { getUserDetails } from "../../services/EndPoint";


function Header({ setOpenOpenLogin, data, setData }) {
    const [hide, setHide] = useState(true);
    const location = useLocation();
    let userId = JSON.parse(localStorage.getItem('userId'));
    const [userName, setUserName] = useState([]);
    let getuserStatus = JSON.parse(localStorage.getItem('userStatus'));
    const [userStatus, setUserStatus] = useState(getuserStatus);
    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: <span className="p-2">You have been successfully logged out</span>,
            duration: 2,
        });
    };

    const logout = () => {
        localStorage.clear();
        setData(false)
        setUserStatus(null);
        success()
    }

    useEffect(() => {
        if (userId) {
            getUserDetails(userId)
                .then((resp) => {
                    setUserName(resp.data);
                })
                .catch((error) => {
                    console.error("errrr", error);
                });
        }
    }, []);


    const items = [
        {
            key: '1',
            label: (
                <>
                    <Link to="/create-profile" className="text-decoration-none">
                        <div className="d-flex">
                            <UserOutlined />
                            <div className="user-info">
                                <Link to="/create-profile">{userName?.firstName} {userName?.lastName}</Link>
                                <br />
                                <p>View Profile</p>
                            </div>
                        </div>
                    </Link>

                </>
            ),
        },
        {
            key: '2',
            label: (

                <Link to="/booking-history" className="text-decoration-none">
                    <div className="d-flex">
                        <HistoryOutlined />
                        <div className="user-info">
                            <Link to="/booking-history">My Booking</Link>
                        </div>
                    </div>
                </Link>

            ),
        },
        {
            key: '3',
            label: (
                <div className="d-flex">
                    <LogoutOutlined />
                    <div className="user-info">
                        <Link to="/" onClick={logout}>Logout</Link>
                        <br />
                        {/* <p>View Profile</p> */}
                    </div>
                </div>
            ),
        },
    ];


    const [isHeaderFixed, setIsHeaderFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsHeaderFixed(true);
            } else {
                setIsHeaderFixed(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            {contextHolder}
            <header>
                <div className="top-header">
                    <div className="email d-lg-none">
                        <span>reservation@superbhotel.in</span>
                    </div>
                    <div className="call d-lg-none">
                        <span>+(040)-48551669 | </span>
                        <span>29388551</span>
                    </div>
                </div>
                <div className="middle-header">
                    <div className="pattern"></div>
                    <div className="container custom-container">
                        <div className="location">
                            <div className="icon">
                                <i className='bx bx-map'></i>
                            </div>
                            <span>5-9-18/9 Saifabad, Opp. Old Gate Secretariat, HYD</span>
                        </div>
                        <div className="email">
                            <div className="icon">
                                <i className='bx bx-envelope'></i>
                            </div>
                            <span>Email Us<br />reservation@superbhotel.in</span>
                        </div>
                        <a className="navbar-brand" href="/"><img src="assets/images/logo.png" alt="logo" className="w-100 img-fluid" /></a>

                        <div className="call">
                            <div className="icon">
                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                                </svg> */}
                                <i className='bx bx-phone phone-icons'></i>
                            </div>
                            <span>+(040)-48551669 | </span>
                            <span>+(040)-29388551</span>
                        </div>
                        <MenuOutlined onClick={() => setHide(!hide)} className="menu" />
                    </div>
                </div>

                {!hide &&
                    <div className="navbar navbar-expand-lg">
                        <div className="container custom-container">
                            <nav className="collapse navbar-collapse d-flex justify-content-end" id="navbarText">
                                <ul className="navbar-nav mx-auto">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link" onClick={() => setHide(true)}>Home</Link>
                                    </li>


                                    <li className="nav-item">
                                        <Link to="/about" className="nav-link" onClick={() => setHide(true)} >About The Hotel</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/offers" className="nav-link">Offers</Link>
                                    </li> 
                                    <li className="nav-item">
                                        <Link to="/gallery" className="nav-link" onClick={() => setHide(true)}>Gallery</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link" onClick={() => setHide(true)}>Contact Us</Link>
                                    </li>
                                    {!userStatus ?
                                        <Button onClick={() => setOpenOpenLogin(true)}>Login / Signup</Button> :
                                        <Dropdown
                                            menu={{
                                                items,
                                            }}
                                            placement="bottom"
                                            arrow={{
                                                pointAtCenter: true,
                                            }}
                                            className="profile-name"
                                        >
                                            <Button>
                                                <Avatar
                                                    className="me-2"
                                                    style={{
                                                        backgroundColor: '#fde3cf',
                                                        color: '#f56a00',
                                                        display: "flex",
                                                        alignItem: "center"
                                                    }}
                                                >
                                                    {`${userName?.firstName?.charAt(0)}${userName?.lastName?.charAt(0)}`}
                                                </Avatar> <span className="text-truncate">{userName?.firstName} {userName?.lastName}</span><DownOutlined /></Button>
                                        </Dropdown>
                                    }
                                </ul>
                            </nav>
                        </div>
                    </div>
                }


                <div className={isHeaderFixed ? 'navbar navbar-expand-lg d-lg-block d-none  fixed' : 'navbar-expand-lg d-lg-block d-none navbar'}
                // className="navbar navbar-expand-lg d-lg-block d-none"
                >
                    <div className="container custom-container">
                        <nav className="collapse navbar-collapse d-flex justify-content-end" id="navbarText">
                            <ul className={!isHeaderFixed ? 'navbar-nav mx-auto login-button' : 'navbar-nav mx-auto login-button-scroll '}>

                                <li className={location?.pathname === "/" ? "nav-item navbar-heading" : "nav-item"}>
                                    <Link to="/" className="nav-link">Home</Link>
                                </li>

                                <li className={location?.pathname === "/about" ? "nav-item navbar-heading" : "nav-item"}>
                                    <Link to="/about" className="nav-link">About The Hotel</Link>
                                </li>

                                <li className={location?.pathname === "/offers" ? "nav-item navbar-heading" : "nav-item"}>
                                    <Link to="/offers" className="nav-link">Offers</Link>
                                </li>

                                <li className={location?.pathname === "/gallery" ? "nav-item navbar-heading" : "nav-item"}>
                                    <Link to="/gallery" className="nav-link">Gallery</Link>
                                </li>

                                <li className={location?.pathname === "/contact" ? "nav-item navbar-heading" : "nav-item"}>
                                    <Link to="/contact" className="nav-link">Contact Us</Link>
                                </li>
                                {/* <Button onClick={() => setOpenOpenLogin(true)}>login / Signup</Button> */}
                                {!userStatus ?
                                    <>
                                        <Button onClick={() => setOpenOpenLogin(true)}>
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#d8a306',
                                                }}
                                                className="me-2"
                                                icon={<UserOutlined />}
                                            />Login / Signup</Button>
                                    </> :
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        placement="bottom"
                                        arrow={{
                                            pointAtCenter: true,
                                        }}
                                        className="profile-name"
                                    >
                                        <Button>
                                            <Avatar
                                                className="me-2"
                                                style={{
                                                    backgroundColor: '#fde3cf',
                                                    color: '#f56a00',
                                                    display: "flex",
                                                    alignItem: "center"
                                                }}
                                            >
                                                {`${userName?.firstName?.charAt(0)}${userName?.lastName?.charAt(0)}`}
                                            </Avatar> <span className="text-truncate">{userName?.firstName} {userName?.lastName}</span><DownOutlined /></Button>
                                    </Dropdown>
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;



