import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Carousel, Image, Select, Tag, Tooltip, notification } from "antd";
import BookNowBottamComp from './BookNowBottamComp';
import { LeftOutlined, RightOutlined, } from '@ant-design/icons';
import { person } from '../../utils/Imagepath';
import { resultRoomChange, resultRoomPlan } from '../../utils/Constants';
import dayjs from 'dayjs';
import moment from 'moment';
function SearchResultComp(props) {
    const { data, loading, selectAdults, selectchildren, setShow, show } = props;
    const [roomCounts, setRoomCounts] = useState({})
    const [roomCountsAdulta, setRoomAddAdulta] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const [isActive, setActive] = useState("false");
    const navigate = useNavigate();
    const selectAdult = JSON.parse(localStorage.getItem('selectAdults'));
    const selectChild = JSON.parse(localStorage.getItem('selectChild'));
    const { Option } = Select;
    const [roomCodeSend, setRoomCodeSend] = useState([])
    const [roomRatePlanCode, setRoomRatePlanCode] = useState([])
    const [showCss, setHideCss] = useState(null)

    const dateFormat = 'DD MMM YYYY';
    let startDateLocal, endDateLocal;
    startDateLocal = JSON.parse(localStorage.getItem('startDate'));
    endDateLocal = JSON.parse(localStorage.getItem('endDate'));
    let startDateFormate = dayjs(startDateLocal).format(dateFormat);
    let endDateFormate = dayjs(endDateLocal).format(dateFormat);

    const [selectedRooms, setSelectedRooms] = useState({});
    const [selectedRoomsNumber, setSelectedRoomsNumber] = useState({});

    const resultRoomChange = {
        "Deluxe Room": "Deluxe",
        "Standard Room": "Standard",
        "Super Deluxe Room": "Super Deluxe",
    };

    const formatRoomInfo = (rooms) => {
        return Object.entries(rooms)
            .filter(([roomName, roomCount]) => roomCount > 0)
            .map(([roomName, roomCount], index, arr) => {
                return `${roomCount} ${roomName}${index !== arr.length - 1 ? ', ' : ''}`;
            })
            .join('');
    };


    const mappedRooms = Object.fromEntries(
        Object.entries(selectedRooms).map(([roomKey, roomCount]) => [
            resultRoomChange[roomKey] || roomKey,
            roomCount
        ])
    );

    const onSelectRoom = (selectedValue, index, roomInfo) => {
        console.log("Guest Allowed", selectedValue, index, roomInfo)
        setSelectedRoomsNumber((prevRoomsNumber) => ({
            ...prevRoomsNumber,
            [index]: selectedValue
        }));

        const allNonZero = Object.values(roomCounts).every(value => value !== "0");
        const isZeroSelected = selectedValue === 0;
        if (isZeroSelected) {
            setShow(false);
        } else if (allNonZero) {
            setShow(true);
        }
        const { room_name, room_Code, rateplan_Code } = roomInfo;
        const currentSelected = selectedRooms[room_name] || 0;
        const previouslySelected = roomCounts[index] || 0;
        const newSelectedRoomsCount = {
            ...selectedRooms,
            [room_name]: currentSelected - previouslySelected + selectedValue,
        };
        setSelectedRooms(newSelectedRoomsCount);
        setRoomCounts((prevCounts) => ({
            ...prevCounts,
            [index]: selectedValue,
        }));
        setRoomCodeSend(prevRoom => ({
            ...prevRoom,
            [index]: room_Code
        }))
        setRoomRatePlanCode(prevPlan => ({
            ...prevPlan,
            [index]: rateplan_Code
        }))
        setRoomCounts(prevCounts => ({
            ...prevCounts,
            [index]: selectedValue
        }));
      
    };
   

    const getTotalAmount = () => {
        let totalAmount = 0;

        // Iterate over room counts to calculate the total amount
        props.data?.[0]?.rooms?.forEach((room, i) => {
            // Get room data
            const { rate, available, offer, room_name } = room;

            // Check if room count is valid
            const count = parseInt(roomCounts[i], 10);
            if (count && count !== '0') {
                const amount = rate * count;

                // Get the count of selected rooms and calculate available left
                const selectedForRoomType = selectedRooms[room_name] || 0;
                const availableLeft = available - selectedForRoomType;

                // Initialize moment.js dates
                const startDateLocals = moment(startDateLocal, 'YYYY-MM-DD');
                const currentDate = moment();
                const offerStartDate = moment(offer?.offer_valid_from);
                const offerEndDate = moment(offer?.offer_valid_to);

                // Check if the offer should be shown based on current and selected dates
                const isOfferVisibleCurrent = currentDate.isBetween(offerStartDate, offerEndDate, null, '[]');
                const isOfferVisibleSelected = startDateLocals.isBetween(offerStartDate, offerEndDate, null, '[]');
                const offerVisible = isOfferVisibleCurrent && isOfferVisibleSelected;

                // Retrieve the offer percentage for the specific room type
                const offerPercentage = offer?.offer_value || 0;

                // Calculate discount and final amount
                const discount = amount * (offerPercentage / 100);
                const totalDiscount = amount - discount;
                const finalAmount = offerVisible ? totalDiscount : amount;

                // Accumulate the total amount
                totalAmount += finalAmount;
            }
        });
        return totalAmount;
    };


    const totalSelectRoom = Object.keys(roomCounts).reduce((total, index) => {
        if (roomCounts[index] > 0) {
            return total + roomCounts[index];
        }
        return total;
    }, 0);

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Warning',
            description:
                'You will have to add or choose another room, as a maximum of 2 adults can stay in one room.',
            placement: "top"
        });
    };

    const selectAdultss = JSON.parse(localStorage.getItem('selectAdults'));


    const bookNowAdultsAndRoom = () => {
        if (totalSelectRoom * 2 >= selectAdultss && totalSelectRoom * 2 >= selectAdultss) {
            navigate("/book-now", {
                state: {
                    roomCounts: roomCounts,
                    totalAmount: getTotalAmount(),
                    totalSelectRoom: totalSelectRoom,
                    roomCodeSend: roomCodeSend,
                    roomRatePlanCode: roomRatePlanCode
                }
            });
        } else if (selectAdultss <= totalSelectRoom && selectAdultss <= totalSelectRoom) {
            openNotificationWithIcon('warning');
        }
        else {
            openNotificationWithIcon('warning');
        }
    };

    const arrowStyle = {
        fontSize: '24px',
        color: '#727171',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: '1',
        background: '#fff',
        borderRadius: '50px',
        width: '28px',
        height: '28px'
    };

    const Arrow = ({ type, onClick }) => {
        if (type === 'left') {
            return <LeftOutlined style={{ ...arrowStyle, left: '6px' }} onClick={onClick} />;
        } else if (type === 'right') {
            return <RightOutlined style={{ ...arrowStyle, right: '6px' }} onClick={onClick} />;
        }
        return null;
    };

    // Function to get rate plan description
    const getRateplanDescription = (rateplanCode) => {
        const match = rateplanCode?.match(/-(cp|ep)$/);
        return match ? resultRoomPlan[match[1]] : "";
    };
    const storedDays = localStorage.getItem("daysBetween");


    const totalAdults = selectAdults || selectAdult;
    const totalChildren = selectChild !== 0 ? (selectChild || selectchildren) : null;
    let childAges = JSON.parse(localStorage.getItem('childAges'));

    const output = childAges.map((age, index) => {
        return `${index + 1} children age ${age}`;
    }).join(', ');



    return (
        <>
            {contextHolder}
            <div className={showCss ? "inner-card-12 d-flex" : "inner-card-12"}>
                <Card loading={loading} className='border-0'>
                    {props?.data?.length > 0 && props?.data[0]?.rooms?.map((result, i) => {
                        let discountPercentage = result?.offer?.offer_value;
                        const { room_name, rate, available, rateplan_Code } = result;
                        const amount = roomCounts[i] ? rate * roomCounts[i] : rate;
                        const discount = amount / 100 * discountPercentage;
                        const totalDiscount = amount - discount;
                        const rateplanDescription = getRateplanDescription(rateplan_Code);

                        // Calculate how many rooms are left to select globally
                        const selectedForRoomType = selectedRooms[room_name] || 0;
                        const availableLeft = available - selectedForRoomType;
                        let startDateLocals = moment(startDateLocal, 'YYYY-MM-DD');
                        const currentDate = moment();
                        const offerStartDate = moment(result.offer?.offer_valid_from);
                        const offerEndDate = moment(result.offer?.offer_valid_to);

                        // Check if the offer should be shown based on the current date
                        const isOfferVisibleCurrent = currentDate.isBetween(offerStartDate, offerEndDate, null, '[]');
                        const isOfferVisibleSelected = startDateLocals.isBetween(offerStartDate, offerEndDate, null, '[]');
                        const formattedEndDate = offerEndDate.format('D MMMM, YYYY');
                        const offerVisible = isOfferVisibleCurrent && isOfferVisibleSelected;

                        return (
                            <div className="card" key={i}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-md-9 col-lg-10'>
                                            <div className='row'>

                                                <div className="col-md-5 pe-lg-0">
                                                    <div className="image-gallery">

                                                        <div className="main-image">
                                                            <Carousel
                                                                arrows
                                                                dots
                                                                prevArrow={<Arrow type="left" />}
                                                                nextArrow={<Arrow type="right" />}
                                                                infinite={true}
                                                                effect='fade'
                                                                className="custom-carousel"
                                                            >
                                                                {result?.images.map((image, index) => (
                                                                    <div key={index}>
                                                                        <Image
                                                                            src={image?.image_url}
                                                                            preview={false}
                                                                            style={{ width: '100%', height: 'auto' }}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </Carousel>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="content col-md-7 pe-lg-0">
                                                    <div className='room-title-and-date pt-3'>
                                                        <div className='d-flex justify-content-between'>
                                                            <h3 className='text-uppercase'> {result.room_name} </h3>

                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <div className='room_title me-2'>Rate Plan : </div>
                                                            <div>{rateplanDescription}</div>
                                                        </div>
                                                        {/* <span>Check-In Date : {result?.startDate}</span> | <span>Check-Out Date : {result?.endDate}</span> */}
                                                        <span>Check-In : {startDateFormate}</span> | <span>Check-Out : {endDateFormate}</span>
                                                    </div>
                                                    <div className='d-flex align-items-center'>
                                                        <div className='room_title' style={{ marginTop: "3px" }}> Guest Allowed </div>
                                                        <div className='ms-2'>
                                                            {Array.from({ length: result.room_occupancy }).map((_, index) => (
                                                                <img key={index} src={person} className="mb-0" style={{ width: "15px", height: "15px" }} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div className="amenities">
                                                        <p>Amenities</p>
                                                        <ul>
                                                            <li><img src="assets/images/room-service.png" alt="icon" /> 24*7 Room Service</li>
                                                            <li><img src="assets/images/restaurant.png" alt="icon" />Restaurant</li>
                                                            <li><img src="assets/images/internet.png" alt="icon" />Internet</li>
                                                            <li><img src="assets/images/air-conditioner.png" alt="icon" />Air Conditioning</li>
                                                        </ul>
                                                    </div>

                                                    <div className="facilities pb-3">
                                                        <p>Other Facilities</p>
                                                        <ul>
                                                            <li>Free Wi-Fi,</li>
                                                            <li>Flat-screen TV,</li>
                                                            <li>Paid Dry Cleaning,</li>
                                                            <li>Paid Pickup & Drop,</li>
                                                            <li>Restaurant with Dining Menu,</li>
                                                            <li>Veg/Non-Veg Food,</li>
                                                            <li>Paid Airport Transfer</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-2 d-flex justify-content-center align-items-end">
                                            <div className="rate-book-info">
                                                <div className='row'>

                                                    <div className='col-md-12 col-6'>
                                                        {offerVisible ? (
                                                            <>
                                                                <Tag bordered={false} color="green" className='mb-3 offer_strip offer_strip2'>
                                                                    {result?.offer?.offer_type}
                                                                </Tag>

                                                                <Tag className='mb-2 py-1 tag_room'>
                                                                    {result?.offer?.offer_value}% off
                                                                </Tag>
                                                                <br />
                                                                <div className="rate-offer text-decoration-none">
                                                                    End of {moment(result?.offer?.offer_valid_to).format('D MMMM, YYYY')}
                                                                </div>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="rate-offer">
                                                                        <i className='bx bx-rupee'></i>{amount}
                                                                    </div>
                                                                    <div className="rate">
                                                                        <i className='bx bx-rupee'></i>{totalDiscount}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className='d-flex align-items-center'>
                                                                    <div className="rate">
                                                                        <i className='bx bx-rupee'></i>{amount}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}

                                                    </div>
                                                    <div className='col-md-12 col-6'>
                                                        {/* <p>{selectedRoomsNumber[i]} Room Per Night</p> */}
                                                        <p>{selectedRoomsNumber[i] || 1} Room{selectedRoomsNumber[i] > 1 ? 's' : ''} Per Night</p>
                                                        <div className='select-amount'>
                                                            <Select
                                                                onChange={(value) => onSelectRoom(value, i, result, offerVisible ? totalDiscount : amount)}
                                                                defaultValue={null}
                                                                placeholder="Select Room"
                                                                style={{ width: '100%' }}
                                                                className='mb-3'
                                                                allowClear
                                                            >
                                                                {/* {result?.available && result.rate && [...Array(result.available)].map((_, index) => (
                                                        <Option key={index + 1} value={index + 1} >
                                                            {index + 1} - (<span>&#8377; {totalDiscount * (index + 1)})</span>
                                                        </Option>
                                                    ))} */}
                                                                {!offerVisible ?
                                                                    <>
                                                                        {[...Array(available)].map((_, i) => (
                                                                            <Option
                                                                                key={i + 1}
                                                                                value={i + 1}
                                                                                disabled={i + 1 > availableLeft}
                                                                            >
                                                                                {i + 1} - (<span>&#8377; {amount * (i + 1)})</span>
                                                                            </Option>
                                                                        ))}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {[...Array(available)].map((_, i) => (
                                                                            <Option
                                                                                key={i + 1}
                                                                                value={i + 1}
                                                                                disabled={i + 1 > availableLeft}
                                                                            >
                                                                                {i + 1} - (<span>&#8377; {totalDiscount * (i + 1)})</span>
                                                                            </Option>
                                                                        ))}
                                                                    </>
                                                                }


                                                            </Select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Card>
                <div className='d-lg-block d-md-block d-none'>
                    <div className={`${isActive ? "hotel_details_show" : "hotel_details"}`}>

                        {show && getTotalAmount() !== 0 && (
                            <>
                                <BookNowBottamComp
                                    roomCounts={roomCounts}
                                    data={data}
                                    setHideCss={setHideCss}
                                    setShow={setShow}
                                    getTotalAmount={getTotalAmount}
                                    bookNowAdultsAndRoom={bookNowAdultsAndRoom}
                                    selectchildren={selectchildren}
                                    selectChild={selectChild}
                                    selectAdult={selectAdult}
                                    selectAdults={selectAdults}
                                    totalSelectRoom={totalSelectRoom}
                                // offerVisible={offerVisible}
                                />
                            </>
                        )}
                    </div>
                </div>



                {/* d-block d-lg-none */}
                {show && getTotalAmount() !== 0 && (
                    <div className="book-room-info d-block d-lg-none">
                        <div className="book-room-amount">
                            <div className='room-info gap-2'>
                                <h6>
                                    {/* {Object.keys(roomCounts).map((index, i, arr) => {
                                        const roomCount = roomCounts[index];
                                        const roomCode = props.data[index]?.room_Code;
                                        const capitalizedRoomCode = roomCode
                                            ? roomCode
                                                .toLowerCase()
                                                .split(" ")
                                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                .join(" ")
                                            : "";
                                        if (roomCount > 0) {
                                            console.log("Rendering room:", roomCount, capitalizedRoomCode);
                                            return (
                                                <React.Fragment key={`${index}`}>
                                                    {roomCount} {capitalizedRoomCode} Room
                                                    {i !== arr.length - 1 ? ", " : ""}
                                                </React.Fragment>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}, */}
                                    {/* <h6 className='text-center'>{formatRoomInfo(selectedRooms)}</h6> */}
                                    <h6 className='text-center'>{formatRoomInfo(mappedRooms)} Room</h6>
                                </h6>

                            </div>
                            <div className="room-info gap-2">
                                <h6>
                                    {totalAdults} {totalAdults === 1 ? "Adult" : "Adults"},
                                </h6>

                                <h6>
                                    {totalChildren}{" "}
                                    {totalChildren && ` ${totalChildren === 1 ? "child" : "children"}`}
                                </h6>

                            </div>
                            {selectChild !== 0 && (
                                <div className="room-info text-center">
                                    <p className='text-white mb-0 lh-sm mt-1'>{output}</p>
                                </div>
                            )}

                            <div className="room-info border-top pt-2 mt-2 justify-content-between">
                                <h6 className="total-amm lh-sm">
                                    <p className='mb-0'>Total Amount </p>
                                    <span className="roomFor">
                                        For , {totalSelectRoom}{" "}
                                        {totalSelectRoom === 1 ? "Room" : "Rooms"}
                                    </span>
                                </h6>
                                <h6 className='text-white ms-4 lh-sm'>
                                    <p className='mb-0 fw-normal'><small>Per Night</small> </p>
                                    <h6 className="fw-bold">₹ {storedDays * getTotalAmount()}</h6>
                                </h6>
                                <button className="btn" onClick={bookNowAdultsAndRoom}>
                                    Book Now
                                </button>
                            </div>

                        </div>
                    </div>
                )}

            </div>
        </>
    )
}



export default SearchResultComp