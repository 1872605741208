
import { instance, instance_PHP } from './axios';

const uri = {
    fetchList: "/Values/show_rooms",
    saveGuestBookingdetails: "/Home/SaveGuestBookingdetaisl",
    userloginSignUp: "LoginUser/SignupLogin",
    otpEnteredUser: "/LoginUser/VerifyOTP",
    createProfile: "/LoginUser/UpdateUserProfile",
    Updatebillinginformation: "/LoginUser/Updatebillinginformation",
    userDetail: "LoginUser/GetUserDetails",
    mailVerifyFunction: "/LoginUser/SendEmailOtp",
    otpConfirmationEmail: "/LoginUser/VerifyOTPEmail",
    contectUsComment: "/Home/ContactInfo",
    bookinghotelConform: "/Home/SaveGuestBookingdetails",
    getBookingsForUser: "/HotelBooking/GetBookingsForUser",
    getBookingsForUser: "/HotelBooking/GetBookingsForUser",
    fetchAllData: "/Home/RoomDetails",

    //   php API End Point
    galleryImage: "/imagelist/gallery",
    roomTypeImage: "/imagelist/room",
    roomTypeAailability: "/room/availability",
    createbookinghotel: "/bookings/create",
    CheckPaymentAmount: "/bookings/check-payment-amount",
    offer: "https://mysuperb.app/v1/offers",
    BookingHistory: "/bookingslist/",
    bookingCancel: "/bookings/cancelation"
};

export const fetchCategoryList = async (reqBody) => {
    return await instance.get(uri.fetchList, reqBody);
};
export const fetchOfferList = async () => {
    return await instance.get(uri.offer);
};


export const saveGuestBookingdetails = async (payload) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return await instance.post(uri.saveGuestBookingdetails, payload,
        { headers: headers, });

};


export const userloginSignUp = async (payload) => {
    const headers = {
        "Content-Type": "application/json",
    };
    return await instance.post(uri.userloginSignUp, payload,
        { headers: headers, });

};

export const otpConfirmationUser = async (payload) => {
    return await instance.post(uri.otpEnteredUser, payload);

};

export const createProfileUser = async (payload) => {
    return await instance.post(uri.createProfile, payload);
};

export const Updatebillinginformation = async (payload) => {
    return await instance.post(uri.Updatebillinginformation, payload);
};

export const getUserDetails = async (id) => {
    return await instance.get(uri.userDetail + "/" + id);
};

export const mailVerifyFunction = async (payload) => {
    return await instance.post(uri.mailVerifyFunction, payload);
};

export const otpConfirmationEmail = async (authenticateOtp) => {
    return await instance.post(uri.otpConfirmationEmail, authenticateOtp);
};

export const contectUsComment = async (payload) => {
    return await instance.post(uri.contectUsComment, payload);
};

export const getBookingsForUser = async (payload) => {
    try {
        const response = await instance.get(uri.getBookingsForUser, { params: payload });
        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        throw error;
    }
};

export const CheckPaymentAmount = async (payload) => {
    return await instance_PHP.post(uri.CheckPaymentAmount, payload);
};


export const fetchAllData = async (reqBody) => {
    try {
        const response = await instance_PHP.get(uri.roomTypeAailability, { params: reqBody });
        return response.data;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};


export const bookinghotelConform = async (payload) => {
    return await instance_PHP.post(uri.createbookinghotel, payload);
};

export const galleryImage = async () => {
    return await instance_PHP.get(uri.galleryImage);
};

export const getRoomTypeImage = async () => {
    return await instance_PHP.get(uri.roomTypeImage);
};


// export const getBookingHistorys = async (params) => {
//     try {
//         const response = await instance_PHP.get(uri.BookingHistory, { params: params });
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching bookings:", error);
//         throw error;
//     }
// };

export const getBookingHistorys = async (params) => {
    try {
        const url = `${uri.BookingHistory}${params.userId}/${params.Type}`;
        const response = await instance_PHP.get(url);
        return response.data;
    } catch (error) {
        console.error("Error fetching bookings:", error);
        throw error;
    }
};



export const getbookingCancel = async (payload) => {
    return await instance_PHP.post(uri.bookingCancel, payload);
};