import axios from "axios";

// Base URLs from environment variables
const BASE_URL = process.env.REACT_APP_SERVER_URL;
const BASE_URL_PHP = process.env.REACT_APP_SERVER_URL_PHP;

// Create an Axios instance for the main API
export const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 100000, // Timeout in milliseconds
    maxContentLength: 1000, // Max content length in bytes
    maxRedirects: 2, // Max redirects
});

// Request interceptor for instance
instance.interceptors.request.use((req) => {
    // You can modify the request here if needed
    return req;
});

// Create an Axios instance for the PHP API
export const instance_PHP = axios.create({
    baseURL: BASE_URL_PHP,
    timeout: 100000, // Timeout in milliseconds
    maxContentLength: 1000, // Max content length in bytes
    maxRedirects: 2, // Max redirects
});

// Request interceptor for instance_PHP
instance_PHP.interceptors.request.use((req) => {
    // You can modify the request here if needed
    return req;
});
