import { Affix } from 'antd';
import React from 'react'
import '../SearchResult/SearchResult.scss';
import { resultRoomCode } from '../../utils/Constants';

const BookNowBottamComp = ({ roomCounts,
    data,
    getTotalAmount,
    bookNowAdultsAndRoom,
    selectchildren,
    selectChild,
    selectAdult,
    selectAdults,
    totalSelectRoom,
    setShow,
    setHideCss
}) => {
    const totalAdults = selectAdults || selectAdult;
    const totalChildren = selectChild !== 0 ? (selectChild || selectchildren) : null;
    let childAges = JSON.parse(localStorage.getItem('childAges'));
 
    const output = childAges.map((age, index) => {
        return `${index + 1} children age ${age}`;
    }).join(', ');

    const fetchData = () => {
        setShow(false)
        setHideCss(false)
    }
    const storedDays = localStorage.getItem("daysBetween");
    return (
        <>
            <Affix>
                <div className="booking-details">
                    <div className="room-info">
                        <h6 className='heading-booking'>
                            {Object.keys(roomCounts).map((index, i, arr) => {
                                const roomCount = roomCounts[index];
                                const roomCode = data?.[0]?.rooms[index]?.room_Code;
                                if (roomCount > 0) {
                                    return (
                                        <React.Fragment key={`${index}`}>
                                            {roomCount} {resultRoomCode[roomCode]}{i !== arr.length - 1 ? ', ' : ''}
                                            {/* <br /> */}
                                        </React.Fragment>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </h6>

                    </div>
                    <div className="room-info">

                        <h6 className='room-for'>
                            For  {totalAdults} {totalAdults === 1 ? "Adult" : "Adults"},{" "}
                            {selectchildren > 0 && `${selectchildren} ${selectchildren === 1 ? 'child' : 'children'}`}{" "}
                            {totalSelectRoom} {totalSelectRoom === 1 ? "Room" : "Rooms"}{" "}
                            <br />
                            {output}
                        </h6>
                        <hr></hr>
                        <h6 className='room-for'>
                            Night : &nbsp;
                            {storedDays}
                        </h6>

                        <h6 className='total-amount'>Total Amount:   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   Rs: {storedDays * getTotalAmount()}</h6>
                    </div>

                    <div className="room-info">
                        <button className="w-100 book-now-btn" onClick={bookNowAdultsAndRoom}>Book Now</button>
                    </div>

                </div>
            </Affix>
        </>
    )
}

export default BookNowBottamComp