
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import "../Home/Home.scss"
import { done, errorImg } from '../../utils/Imagepath';
import axios from "axios";


const PaymentStatus = () => {
    const [data, setData] = useState("")
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const getData = location?.search.replace("?status=", "");
    const [orderInfo, setOrderInfo] = useState(null);
    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        const handlePayment = async () => {

            try {
                const response = await axios.post('https://mysuperb.app/v1/paydecrypt', { encresp: getData });
                console.log("response1", response?.data);
                console.log("response2", response.data?.data);
                // const dataText = "order_id=618384483&tracking_id=313011364816&bank_ref_no=bs6a66251c81dc&order_status=Success&failure_message=&payment_mode=Credit Card&card_name=Visa&status_code=null&status_message=Transaction is Successful&currency=INR&amount=2450.00&billing_name=&billing_address=&billing_city=&billing_state=&billing_zip=&billing_country=&billing_tel=&billing_email=&delivery_name=Parvreen&delivery_address=&delivery_city=&delivery_state=&delivery_zip=&delivery_country=&delivery_tel=919873198678&merchant_param1=&merchant_param2=&merchant_param3=&merchant_param4=&merchant_param5=&vault=N&offer_type=null&offer_code=null&discount_value=0.0&mer_amount=2450.00&eci_value=null&retry=N&response_code=&billing_notes=&trans_date=30/04/2024 15:30:55&bin_country=INDIA";
                const pattern = /order_id=(\d+)&.*?tracking_id=(\d+)&.*?order_status=([^&]+).*?amount=([\d.]+)/;
                const match = response.data?.data?.match(pattern);
                // const match = response?.data?.match(pattern);

                if (match) {
                    const order_id = match[1];
                    const tracking_id = match[2];
                    const order_status = match[3];
                    const amount = match[4];
                    localStorage.setItem('payment_id', JSON.stringify(order_id));
                    localStorage.setItem('payment_status', JSON.stringify(order_status));
                    localStorage.setItem('tracking_id', JSON.stringify(tracking_id));
                    setOrderInfo({ order_id, order_status, amount, tracking_id });

                } else {
                    setOrderInfo(null);
                }
            } catch (error) {
                setLoading(false);
                console.log("error", error);
            }
        };
        handlePayment();
    }, [getData]);





    // useEffect(() => {
    //     const handlePayment = async () => {
    //         try {
    //             const response = await axios.post('https://mysuperb.app/v1/paydecrypt', { encresp: getData });
    //             console.log("response", response?.data);
    //             localStorage.setItem('payment_id', JSON.stringify(order_id));
    //             localStorage.setItem('payment_status', JSON.stringify(order_status));
    //             localStorage.setItem('tracking_id', JSON.stringify(tracking_id));
    //             setOrderInfo({ order_id, order_status, amount, tracking_id });

    //         } catch (error) {
    //             setLoading(false);
    //             console.log("error", error);
    //         }
    //     };

    //     handlePayment();
    // }, [getData]);


    let payments_id = JSON.parse(localStorage.getItem('payment_id'));
    let payment_status = JSON.parse(localStorage.getItem('payment_status'));
    let tracking_id = JSON.parse(localStorage.getItem('tracking_id'));

    const handleUpdate = () => {
        const payload = {
            booking_id: payments_id,
            hotelBooking: {
                PaymentStatus: payment_status,
                pg_reference_no: tracking_id,
                Amount: orderInfo?.amount
            }
        };

        setLoading(true);
        axios.post('https://api.superbhotel.in/Home/UpdateGuestBookingdetails', payload)
            .then((response) => {
                setLoading(false);
                console.log("response.data", response.data);
                setFilterData(response?.data)
            })
            .catch((error) => {
                setLoading(false);
                console.log("error", error);
            });
    };


    useEffect(() => {
        const timer = setTimeout(() => {
            handleUpdate();
        }, 2000);

        return () => clearTimeout(timer);
    }, [payments_id, payment_status, tracking_id]);

    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 20000);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <div className='payment-status'>
                {filterData?.PaymentStatus !== "Failed" ?
                    <Card className='text-center my-5 payment-status-card' loading={loading}>
                        {orderInfo?.order_status && orderInfo?.order_status !== "Success" ?
                            <>
                                <div className="btn-box">
                                    <img src={errorImg} alt='' style={{ width: "150px" }} />
                                    <h1 className='err-color'>Payment {orderInfo?.order_status}</h1>
                                    <p>Your Payment could not be processed </p>
                                    <div>
                                        <p>Booking ID : {orderInfo?.order_id}</p>
                                        <p>Reference ID : {orderInfo?.tracking_id}</p>
                                        <p>Payment Status : {orderInfo?.order_status}</p>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="btn-box">
                                    <img src={done} alt='' style={{ width: "150px" }} />
                                    <h1 className='success'>Payment {orderInfo?.PaymentStatus}</h1>
                                    <p><b>Thank you ! Your payment of {orderInfo?.amount} has been received</b></p>
                                    <p>You have completed your payment</p>

                                    <div>
                                        <p>Booking ID : {orderInfo?.order_id}</p>
                                        <p>Reference ID : {orderInfo?.tracking_id}</p>
                                        <p>Payment Status : {orderInfo?.order_status}</p>
                                    </div>
                                </div>
                            </>
                        }

                    </Card>
                    :
                    <Card className='text-center my-5 payment-status-card' loading={loading}>
                        <>
                            <div className="btn-box">
                                <img src={errorImg} alt='' style={{ width: "150px" }} />
                                <h1 className='err-color'>Payment {filterData?.PaymentStatus}</h1>
                                <p>Your Payment could not be processed</p>
                                <div>
                                    <p>Booking ID : {orderInfo?.order_id}</p>
                                    <p>Reference ID : {filterData?.pg_reference_no}</p>
                                    <p>Payment Status : {filterData?.PaymentStatus}</p>
                                </div>
                            </div>
                        </>
                    </Card>
                }
            </div>
        </>
    );
}

export default PaymentStatus;