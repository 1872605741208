





import React, { useEffect, useState } from 'react'
import Offers from './Offers'


const OfferComp = () => {

    return (

        <>
            <section class="mt-5 mb-5">
                <div className='container'>
                    <div className='row mb-4'>
                        <div className='col-md-12'>
                            <h2>Offers & Discounts </h2>
                        </div>
                    </div>
                    
                    <div className='row'>
                        <Offers />
                    </div>
                </div>
            </section>
        </>
    )

}

export default OfferComp