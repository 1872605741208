import React, { useState } from 'react'
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import BirlaMandir from './components/Location-and-surroundings/BirlaMandir/BirlaMandir';
import Charminar from './components/Location-and-surroundings/Charminar/Charminar';
import HussainSagar from './components/Location-and-surroundings/HussainSagar/HussainSagar';
import PuriJagannathTemple from './components/Location-and-surroundings/PuriJagannathTemple/PuriJagannathTemple';
import GolcondaFort from './components/Location-and-surroundings/GolcondaFort/GolcondaFort';
import QutubShahiTomb from './components/Location-and-surroundings/QutubShahiTomb/QutubShahiTomb';
import TermsAndConditions from './components/Hotel-Policy/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './components/Hotel-Policy/PrivacyPolicy/PrivacyPolicy';
import PaymentPolicy from './components/Hotel-Policy/PaymentPolicy/PaymentPolicy';
import SearchResult from './components/SearchResult/SearchResult';
import BookNowRoom from './components/Home/BookNowRoom';
import ScrollToTop from './utils/ScrollToTop';
import PaymentStatus from './components/Home/PaymentStatus';
import WebTermsUse from './components/Hotel-Policy/WebsiteTermsUse/WebTermsUse';
import Login from './components/auth/Login';

import OtpPage from './components/auth/OtpPage';
import CreateProfile from './components/auth/CreateProfile';
import BookingHisotry from './components/auth/BookingHisotry';
import HotelGallery from './components/gallery/HotelGallery';
import OfferComp from './components/Home/OfferComp';


const App = () => {
  const [OpenLogin, setOpenOpenLogin] = useState(null);
  const [openOtp, setOpenOtp] = useState(null);
  const [data, setData] = useState(null)

  return (

    <>
      <div className="App">
        <Header setOpenOpenLogin={setOpenOpenLogin} data={data} setData={setData} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/birla-mandir" element={<BirlaMandir />} />
          <Route path="/charminar" element={<Charminar />} />
          <Route path="/hussain-sagar" element={<HussainSagar />} />
          <Route path="/puri-jagannath-temple" element={<PuriJagannathTemple />} />
          <Route path="/golconda-fort" element={<GolcondaFort />} />
          <Route path="/qutub-shahi-tomb" element={<QutubShahiTomb />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/payment-policy" element={<PaymentPolicy />} />
          <Route path="/search-result" element={<SearchResult />} />
          <Route path="/book-now" element={<BookNowRoom />} />
          <Route path="/gallery" element={<HotelGallery />} />
          <Route path="/payment-status" element={<PaymentStatus />} />
          <Route path="/website-terms-use" element={<WebTermsUse />} />
          <Route path="/create-profile" element={<CreateProfile setData={setData} />} />
          <Route path="/booking-history" element={<BookingHisotry />} />
          <Route path="/offers" element={<OfferComp />} />

          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
        <Footer />

      </div>
      <ScrollToTop />


      <Login
        setOpenOpenLogin={setOpenOpenLogin}
        OpenLogin={OpenLogin}
        setOpenOtp={setOpenOtp}
      />

      <OtpPage
        setOpenOtp={setOpenOtp}
        openOtp={openOtp}
        setOpenOpenLogin={setOpenOpenLogin}
      />
    </>

  );
}

export default App;
