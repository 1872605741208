import { Card, Col, Row } from 'antd'
import React, { useState } from 'react'
import HotelCancellationModal from './HotelCancellationModal';

const BookingDetatailsComp = ({ bookingDetailsData, setBookingDetails }) => {
    let item = bookingDetailsData

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
        setBookingDetails(false);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        console.log('Booking has been cancelled');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setBookingDetails(true);
    };


    return (
        <>
            <div className=''>
                <Row gutter={[16, 16]}>
                    <Col key={item.booking_id} xs={24} sm={12} lg={24}>
                        <Card className='booking-card w-100' >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className='booking-title' style={{ marginLeft: '10px' }}>Booking Details</h3>
                                <hr />
                            </div>

                            <div>
                                <table class="booking-table">
                                    <tr>
                                        <td>Booking ID</td>
                                        <td style={{ color: '#1890ff', fontSize: '18px', marginBottom: '12px' }}>{item?.booking_id}</td>
                                    </tr>

                                    <tr>
                                        <td>Reference ID</td>
                                        <td>{item?.pg_reference_no}</td>
                                    </tr>
                                    <tr>
                                        <td>Check-In Date</td>
                                        <td>{item?.booking_to}</td>
                                    </tr>
                                    <tr>
                                        <td>Check-Out Date</td>
                                        <td>{item?.booking_from}</td>
                                    </tr>
                                    <tr>
                                        <td>Amount</td>
                                        <td>{item?.amount}</td>
                                    </tr>
                                    <tr>
                                        <td>Status</td>
                                        <td>{item?.payment_status}</td>
                                    </tr>
                                    <tr>
                                        <td>Guest Name</td>
                                        <td>{`${item?.user_title} ${item?.user_first_name} ${item?.user_last_name}`}</td>
                                    </tr>
                                    <tr>
                                        <td>Number of Rooms</td>
                                        <td>{item?.rooms}</td>
                                    </tr>
                                    <tr>
                                        <td>Room Name</td>
                                        <td>{item?.room_name}</td>
                                    </tr>
                                </table>
                            </div>


                            {/* <div className="text-center">
                                <button className='mx-2 cancellation-button'>Back</button>
                                <button className='cancellation-button' onClick={showModal}>Booking Cancel</button>
                            </div> */}

                        </Card>
                    </Col>

                </Row>
                <HotelCancellationModal
                    item={item}
                    isModalVisible={isModalVisible}
                    showModal={showModal}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                />
            </div>
        </>
    )
}

export default BookingDetatailsComp