import React, { useEffect, useState } from 'react';
import './Home.scss';
import { Modal } from 'antd';
import { CheckPaymentAmount } from '../../services/EndPoint';


const GuestDetailsPage = ({
    openGuestDetailsPage,
    setOpenGuestDetails,
    payloadData,
    roomTypeSelect,
    resultArray,
    randomNumber
}) => {
    const [dataSend, setDataSend] = useState("");
    const [messageData, setMessageData] = useState("");
    const [btnHide, setButtonHide] = useState(null)

    const handlePaymentCheck = async (e) => {
        try {
            const payload = {
                booking_id: randomNumber,
                Amount: payloadData?.Amount,
            }
            const response = await CheckPaymentAmount(payload);
            if (response?.data?.message === "Payment amount matches the amount stored in the database.") {
                setButtonHide(false);
            } else {
                setMessageData(response?.data?.message)
                setButtonHide(true);
            }
        } catch (error) {
            console.log("err", error);
        }
    };

    useEffect(() => {
        handlePaymentCheck();
    }, [])

    const handlePayment = () => {
        const axios = require('axios');
        const payload = {
            bookingid: randomNumber,
            amount: payloadData?.Amount,
            name: payloadData.FirstName + ' ' + payloadData.LastName,
            email: payloadData.Email,
            mobileno: payloadData.Mobile,
            country: payloadData.country,
            redirect_url: "https://superbhotel.in/payment-status",
            cancel_url: "https://superbhotel.in/payment-status"
        }

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://mysuperb.app/v1/pay',
            data: payload
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setDataSend(response?.data.data);
                let url = response?.data?.data
                // window.open(url, "noreferrer");
                window.location.href = url
            })
            .catch((error) => {
                console.log(error);
            });
    }



    return (
        <>
            <Modal
                open={openGuestDetailsPage}
                footer={false}
                onCancel={() => setOpenGuestDetails()}
                width={800}
                centered
            >

                <div className="review-booking-modal">
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h4>Review your Booking</h4>
                        </div><hr />
                        {/* <div className="room-details">
                            <div className="date justify-content-space-evently align-items-center">
                                <h5>Check-In : {payloadData?.BookingTo}</h5>&nbsp;&nbsp;&nbsp;
                                <h5>Check-Out : {payloadData?.Bookingfrom}</h5>
                            </div>
                            <div className="content">
                                <div className="facilities">
                                    <h5 className='room-type'>{Object.keys(roomTypeSelect).map((key, index) => (
                                        <>
                                            {resultArray[index]}
                                            &nbsp;
                                            {roomTypeSelect[key]}
                                            {index !== Object.keys(roomTypeSelect).length - 1 && ','}
                                            &nbsp;
                                        </>
                                    ))}</h5>

                                    <ul>
                                        <li>Deluxe Rooms with Free Wi-Fi,</li>
                                        <li>include a flat-screen TV,</li>
                                        <li>Dry Cleaning Service,</li>
                                        <li>Restaurant with Dining Menu,</li>
                                        <li>Veg/Non-Veg Food is Allowed,</li>
                                        <li>Paid Airport Transfer,</li>
                                        <li>Paid Pickup & Drop </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="guest-details">
                            <div className="guest-detail-heading text-left">
                                <h5>Guest Details </h5>
                            </div>
                            <div className="content">
                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label>Name : </label>
                                            <span>{`${payloadData?.Guest_title} ${payloadData?.FirstName} ${payloadData.LastName}`}</span>
                                        </div></div>

                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label>Contact Number : </label>
                                            <span>{payloadData?.Mobile}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label>Email : </label>
                                            <span>{payloadData?.Email}</span>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="input-group">
                                            <label>Country : </label>
                                            <span>{payloadData?.Country}</span>
                                        </div></div>

                                    {/* <div className="col-md-6">
                                    <h6>Number of Rooms :</h6>
                                    <p>{payloadData?.Rooms}</p>
                                </div> */}

                                    {payloadData?.GST &&
                                        <>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label>Gst Number : </label>
                                                    <span>{payloadData?.GST}</span>
                                                </div></div>
                                        </>
                                    }



                                    {payloadData?.PassportNo &&
                                        <>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label>Passport Number : </label>
                                                    <span>{payloadData?.PassportNo}</span>
                                                </div></div>
                                        </>
                                    }
                                </div>

                            </div>



                            {/* <div className="guest-detail-heading text-left">
                                <h5>Other Members Guest  Details</h5>
                            </div>
                             */}

                            {/* <div className="content"> */}
                            <div className="row">

                                <div className="col-md-6">
                                    {payloadData?.guestfirstname?.map((item, index) => (
                                        <div className="input-group" key={index}>
                                            {index + 2}). &nbsp;  <label>First Name :  </label>
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                    {/* {payloadData?.guestfirstname?.map((item, index) => (
                                        <ol className="input-group" start={index} key={index}>
                                            <li>    <label>First Name : </label><span>{item}</span></li>
                                        </ol>
                                    ))} */}
                                </div>


                                <div className="col-md-6">
                                    {payloadData?.guestlastname?.map((item, index) => (
                                        <div className="input-group">
                                            <label>Last Name : </label>
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* </div> */}

                            {/* {messageData} */}
                            <div className="total-amount">
                                <h5>Total Amount  :</h5>
                                <span>₹ {payloadData?.Amount}</span>
                            </div>
                            <div className='d-flex justify-content-end mt-5'>
                                <div className="btn-box">
                                    <button
                                        type='submit'
                                        style={{ background: "#bcbbbb" }}
                                        onClick={() => setOpenGuestDetails(false)}
                                        className="btn mx-2">Back</button>
                                </div>
                                {!btnHide &&
                                    <div className="btn-box">
                                        <button
                                            type='submit'
                                            onClick={handlePayment}
                                            className="btn">Comfirm</button>
                                    </div>
                                }

                                {btnHide &&
                                    <>
                                        {messageData}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div >
            </Modal>
        </>
    )
}

export default GuestDetailsPage;