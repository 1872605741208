export const nameRegex = '^[A-Za-z. ]+$';
export const emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$'; //eslint-disable-line
export const phoneRegex = '[6-9]{1}[0-9]{10}$';
export const pincodeRegex = '[1-9]{1}[0-9]{5}$';
export const pancardRegex = '[A-Z]{5}[0-9]{4}[A-Z]{1}$';
export const adhaarRegex = '[0-9]{12}$';
export const ifscRegex = '[A-Z]{4}[0]{1}[A-Z0-9]{6}$';
export const numRegex = '^[0-9]*$';
export const floatRegex = `[+-]?([0-9]*[.])?[0-9]+`;

export const taskStatus = {
  to_do: "to do",
  in_progress: "in progress",
  done: "done"
};

export const resultRoomCode = {
  "double-room": "Deluxe Room",
  "single-bed-room": "Standard Room",
  "super-deluxe-room": "Super Deluxe Room",
};

export const resultRoomChange= {
  "Deluxe Room": "Deluxe",
  "Standard Room": "Standard",
  "Super Deluxe Room": "Super Deluxe",
};


export const resultRatePlanCode = {
  "double-room": "King Size Bed",
  "single-bed-room": "Twin Sharing Bed",
  "super-deluxe-room": "King Size Bed",
};

export const resultRoomPlan = {
  "cp": "With Breakfast",
  "ep": "Without Breakfast",
};








// const imgGallery = [
//   {
//       id: 1,
//       imgUrl: gallery13
//   },
//   {
//       id: 2,
//       imgUrl: gallery14
//   },
//   {
//       id: 3,
//       imgUrl: gallery15
//   },
//   {
//       id: 4,
//       imgUrl: gallery16
//   },

// ];