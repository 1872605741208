
import '../../components/Home/Home.scss';

function About() {
    return (
        <>
            <nav style={{ padding: '15px', background: '#eee', marginBottom: '30px', width: '100%', height: '63px' }} aria-label="breadcrumb">
                <div className="container">
                    <ol className="breadcrumb  me-3" style={{ float: 'right' }}>
                        <li className="breadcrumb-item"><a href="/home" style={{ color: '#000' }}>Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">About</li>
                    </ol>
                </div>
            </nav>
            <section className="about-us mt-5">
                <div className="container">
                    <div className="row mt-5 ">
                        <div className="col-lg-6 col-12">
                            <div className="about me-lg-4">
                                <div className="main-heading">
                                    <h3 className="sub-title">
                                        Welcome to the superb hotels
                                    </h3>
                                    <h2 className="title">Know More About Us</h2>
                                </div>
                                <div className="content">
                                    <span>The Superb Hotels offer deluxe facilities & accommodation.</span>
                                    <p>
                                        It's located 2 Km from Khairatabad Railways Station and around 15 Km from Shilparamam. Plus, it is centrally situated in the heart of both twin cities Hyderabad & Secunderabad and exactly opposite to the new secretariat of Hyderabad, Telangana. The property is surrounded with beautiful Lumbini park, Sanjeevaiah park, Indira park, NTR Garden.
                                    </p>
                                    <p>
                                        Exactly located in the center of the city it is connected to all tourist spots and the IT Hub of Hyderabad and Secunderabad the hotel is well-maintained, with 24*7 customer service. All the rooms providing a city view have air-conditioners, a television, and an attached bathroom with hot and running water. It also offers amenities like room services, medical assistance, and laundry.
                                    </p>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <div className="img-box">
                                        <img src="assets/images/about-two.png" alt="about" className='w-100' />
                                    </div>
                                </div>
                                <div className="col-md-6  col-6">
                                    <div className="img-box" style={{ marginTop: "60px" }}>
                                        <img src="assets/images/about-one.png" alt="about" className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;